import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='We would love to hear from you! Our team at PS Church Leadership is dedicated to providing exceptional service and support. Whether you have a question, feedback, or simply want to say hello, we are here for you.'
        />
        <Divider />
        <ContactForm />
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
