import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Label, Input, Textarea, Button, Message } from 'theme-ui';
import useMailChimp from '@helpers/useMailChimp';

const ContactForm = ({ handleSubmit, submitting, success }) => {
  const [subscribe, setSubscribe] = useState(false);
  const { handleSubmit: handleMailchimpSubmit } = useMailChimp();

  const customCheckboxStyle = {
    margin: '10px 10px',
    cursor: 'pointer',
    width: '16px',
    height: '16px',
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    handleSubmit(event);

    if (subscribe) {
      handleMailchimpSubmit(event);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" />
      {success === true && <Message variant='success'>Thank you for contacting us. We'll get back to you soon!</Message>}
      {success === false && <Message variant='error'>Something went wrong. Please try again later!</Message>}
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-name'>Name</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-organization'>Organization</Label>
        <Input type='text' id='contact-form-organization' name='organization' />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input type='email' id='contact-form-email' name='email' required />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-message'>Message</Label>
        <Textarea id='contact-form-message' name='message' required />
      </Box>
      <Box variant='forms.row'>
      <label style={{ display: 'block', margin: '10px 0' }}>
        Subscribe to our newsletter 
        <input 
          type="checkbox" 
          checked={subscribe} 
          onChange={() => setSubscribe(!subscribe)}
          style={customCheckboxStyle} 
        />
      </label>
      </Box>
      
      <Button variant={submitting ? 'disabled' : 'primary'} disabled={submitting} type='submit'>Submit</Button>
    </form>
  );
};

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  success: PropTypes.bool
};

export default ContactForm;



// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Box, Label, Input, Textarea, Button, Checkbox, Message } from 'theme-ui';
// import useMailChimp from '@helpers/useMailChimp';

// const ContactForm = ({ handleSubmit, submitting, success }) => {
//   const [subscribe, setSubscribe] = useState(false);
//   const { handleSubmit: handleMailchimpSubmit } = useMailChimp();

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();

//     // Handle contact form submission logic
//     handleSubmit(event);

//     // If user opts to subscribe, handle the Mailchimp subscription
//     if (subscribe) {
//       handleMailchimpSubmit(event);
//     }
//   };

//   return (
//     <form onSubmit={handleFormSubmit} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
//       <input type="hidden" name="form-name" value="contact" />
//       {success === true && <Message variant='success'>Thank you for contacting us. We'll get back to you soon!</Message>}
//       {success === false && <Message variant='error'>Something went wrong. Please try again later!</Message>}
//       <Box variant='forms.row'>
//         <Label htmlFor='contact-form-name'>Name</Label>
//         <Input type='text' id='contact-form-name' name='name' required />
//       </Box>
//       <Box variant='forms.row'>
//         <Label htmlFor='contact-form-organization'>Organization</Label>
//         <Input type='text' id='contact-form-organization' name='organization' />
//       </Box>
//       <Box variant='forms.row'>
//         <Label htmlFor='contact-form-email'>Email</Label>
//         <Input type='email' id='contact-form-email' name='email' required />
//       </Box>
//       <Box variant='forms.row'>
//         <Label htmlFor='contact-form-message'>Message</Label>
//         <Textarea id='contact-form-message' name='message' required />
//       </Box>
//       <Checkbox checked={subscribe} onChange={() => setSubscribe(!subscribe)}>Subscribe to our newsletter</Checkbox>
//       <Button variant={submitting ? 'disabled' : 'primary'} disabled={submitting} type='submit'>Submit</Button>
//     </form>
//   );
// };

// ContactForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   submitting: PropTypes.bool,
//   success: PropTypes.bool
// };

// export default ContactForm;

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Box, Label, Input, Textarea, Button, Message } from 'theme-ui';

// const ContactForm = ({ handleSubmit, submitting, success }) => (
//   <form
//     onSubmit={handleSubmit}
//     name="contact"
//     method="POST"
//     data-netlify="true"
//     data-netlify-honeypot="bot-field"
//   >
//     <input type="hidden" name="form-name" value="contact" />
//     {success === true && (
//       <Message variant='success'>
//         Thank you for contacting us. We'll get back to you soon!
//       </Message>
//     )}
//     {success === false && (
//       <Message variant='error'>
//         Something went wrong. Please try again later!
//       </Message>
//     )}
//     <Box variant='forms.row'>
//       <Label htmlFor='contact-form-name'>Name</Label>
//       <Input type='text' id='contact-form-name' name='name' required />
//     </Box>
//     <Box variant='forms.row'>
//       <Label htmlFor='contact-form-organization'>Organization</Label>
//       <Input type='text' id='contact-form-organization' name='organization' />
//     </Box>
//     <Box variant='forms.row'>
//       <Label htmlFor='contact-form-email'>Email</Label>
//       <Input
//         type='email'
//         id='contact-form-email'
//         name='email'
//         required
//       />
//     </Box>
//     <Box variant='forms.row'>
//       <Label htmlFor='contact-form-message'>Message</Label>
//       <Textarea id='contact-form-message' name='message' required />
//     </Box>
//     <Button
//       variant={submitting ? 'disabled' : 'primary'}
//       disabled={submitting}
//       type='submit'
//     >
//       Submit
//     </Button>
//   </form>
// );

// export default ContactForm;

// ContactForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   submitting: PropTypes.bool,
//   success: PropTypes.bool
// };